import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Devices from './Devices';
import HomePage from './HomePage';
import './App.css';
import { AppStateProvider, useAppState } from './AppState';
import axios from "axios";
import Capture from './Capture';
import LoginComponent from './Login';
import Sidebar from './Sidebar';
import { getEndpoint } from './util';
import ListRecordings from './Recordings';
import RecordingInfoPage from './RecordingInfoPage';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('authToken');


const AppContent: React.FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [state, setAppState] = useAppState();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    if (!state.auth_token){
      return;
    }
    const fetchDevices = async () => {
      const endpoint = getEndpoint("http");
      const url = `${endpoint}/listDevices`; // Endpoint should be adjusted server-side to handle user auth token
      const response = await axios.get(url);
      setAppState((state) => ({
        ...state,
        devices: response.data
      }));
    };
    fetchDevices();
  }, [state.auth_token])

  return (
    <div className="App">
      <div className="container">
        <button className="toggle-sidebar-button" onClick={toggleSidebar}>
          <i className="fa fa-bars"></i>
        </button>

        <Sidebar onLinkClick={toggleSidebar} visible={sidebarVisible} />
        <div className="App-content App-header">
          {state.auth_token ? (
            <Routes>
              <Route index path="/" element={<HomePage />} />
              <Route path="/devices" element={<Devices />} />
              <Route path="/capture" element={<Capture />} />
              <Route path="/recordings" element={<ListRecordings/>} />
              <Route path="/recordingInfo/:id" element={<RecordingInfoPage />} />
            </Routes>
          ) : (
            <LoginComponent />
          )}
        </div>
      </div>
    </div>
  );
};

const AppComponent: React.FC = () => {
  return (
    <Router>
      <AppStateProvider>
        <AppContent />
      </AppStateProvider>
    </Router>
  );
};

export default AppComponent;
