import React, { useState, useEffect, useRef } from 'react';
import { useAppState } from './AppState';
import manPath from './manPath';
import { Sensor, Sensors, Device } from "./types";

const RECT_WIDTH = 65;
const RECT_HEIGHT = 36;

export type AugmentedSensor = Sensor & {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type SensorMap = Record<string, { x: number; y: number }>;

interface RectProps {
    sensor: AugmentedSensor;
    onSensorClick: (sensor: AugmentedSensor, target: SVGElement) => void;
}


export interface ManProps {
    sensors: Sensors;
    onSensorClick: (sensor: AugmentedSensor, target: SVGElement) => void
}

const Rect = ({ sensor, onSensorClick }: RectProps) => {
    const rectX = sensor.x - sensor.width / 2;
    const rectY = sensor.y - sensor.height / 2;

    return (
        <g onClick={(event) => onSensorClick(sensor, event.currentTarget as SVGElement)}>

            <rect
                id={sensor.id}
                x={rectX}
                y={rectY}
                width={sensor.width}
                height={sensor.height}
                style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
                rx={0}
                ry={0}
            />
            <text
                x={sensor.x}
                y={sensor.y}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="18"
                fill="black"
            >
                {sensor.label}
            </text>
        </g>
    );
};

const Man = ({ sensors, onSensorClick }: ManProps) => {
    const sensorMap: SensorMap = {
        "right_wrist": { "x": 273.998, "y": 512.165 },
        "left_wrist": { "x": 547.68, "y": 518.899 },
        "right_foot": { "x": 364.427, "y": 907.484 },
        "left_foot": { "x": 454.241, "y": 908.277 },
        "waist": { "x": 407.072, "y": 515.879 },
        "forehead": { "x": 409.334, "y": 118.288 },
        "left_knee": { "x": 464.648, "y": 756.914 },
        "right_knee": { "x": 353.112, "y": 756.713 }
    };

    const augmentedSensors = sensors.map((sensor): AugmentedSensor => ({
        ...sensor,
        ...sensorMap[sensor.id],
        width: RECT_WIDTH,
        height: RECT_HEIGHT,
    }));

    return (
        <div style={{'display':'flex'}}>
        <svg version="1.0" viewBox="0 0 816.000000 1056.000000" preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg">
                <title>FRONT VIEW</title>
                <text x="50%" y="30" text-anchor="middle" fill="#000000" font-size="30" transform="scale(1,1)">FRONT VIEW</text>
            <g transform="translate(0.000000,1056.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d={manPath} />
            </g>
            {augmentedSensors.map(sensor => <Rect key={sensor.id} sensor={sensor} onSensorClick={onSensorClick} />)}
        </svg>
        </div>
    );
};

interface ManSVGProps {
    sensors: Sensor[];
    setLabel: (id: string, label: string) => void;
}

const formatSensorLabel = (id: string) => {
    return id.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};


const ManSVG = ({ sensors, setLabel }: ManSVGProps) => {
    const [appState] = useAppState();
    const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const devices = appState.devices || [];

    const selectRef = useRef<HTMLSelectElement>(null);


    const handleSensorClick = (sensor: Sensor, target: SVGElement) => {
        setSelectedSensor(sensor);
        const rect = target.getBoundingClientRect();
        setMenuPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX
        });
    };

    const handleDeviceClick = (device: Device) => {
        if (selectedSensor) {
            setLabel(selectedSensor.id, device.device_name);
        }
        setSelectedSensor(null);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setSelectedSensor(null);
            }
        };

        if (selectedSensor) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedSensor]);


    const devicesWithBlank: Device[] = [{ device_id: '', device_name: '', last_checked: '', group_id:0, group_name:'', position:'' }, ...devices];
    return (
        <div className="container">
            <Man sensors={sensors} onSensorClick={handleSensorClick} />
            {selectedSensor && (
                <div
                    className="capture-modal"
                    onClick={(e) => {
                        if ((e.target as HTMLElement).className === "capture-modal") {
                            setSelectedSensor(null); // Close modal if clicked outside content
                        }
                    }}
                >
                    <div className="capture-modal-content">
                        <div className="capture-modal-title">
                            {formatSensorLabel(selectedSensor.id)}
                        </div>
                        <div>
                            {devicesWithBlank.map(device => (
                                <>
                                    <button
                                        key={device.device_id}
                                        className={`capture-input-style`}
                                        onClick={() => {
                                            handleDeviceClick(device);
                                            setSelectedSensor(null);
                                        }}
                                    >
                                        {device.device_name? `${device.device_name}-${device.group_id}`: <>&nbsp;</>}
                                    </button>                                                                     
                                </>


                            ))}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ManSVG;