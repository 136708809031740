import React from 'react';
import QueryParamLink from './QueryParamLink';

const HomePage = () => {
  return (
    <div className="button-wrapper">
      <QueryParamLink to="/devices">
        <button className="modern-button"><i className="fa fa-desktop"></i> Devices</button>
      </QueryParamLink>
      <QueryParamLink to="/capture">
        <button className="modern-button"><i className="fa fa-camera"></i> Capture</button>
      </QueryParamLink>
    </div>
  );
};

export default HomePage;
