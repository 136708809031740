import React, { useRef, useState, useMemo } from "react";
import axios, { AxiosError } from "axios";
import Man from "./Man";
import { v4 as uuidv4 } from "uuid"; // Import the v4 function from uuid
import { getEndpoint } from "./util";
import { Recording } from "./types";
import { useAppState } from './AppState';
import { Device } from "./types";
import RecordingInfo from "./RecordingInfo";


interface DurationOption {
  duration: number;
  label: string;
}

interface PeriodOption {
  label: string;
  value: number;
}

const PERIOD_OPTIONS: PeriodOption[] = [
  { label: "1000 Hz", value: 1000 },
  { label: "500 Hz", value: 2000 },
  { label: "250 Hz", value: 4000 },
  { label: "200 Hz", value: 5000 },
  { label: "100 Hz", value: 10000 },
];


const DURATION_OPTIONS: DurationOption[] = [
  { duration: 5000, label: "5 seconds" },
  { duration: 10000, label: "10 seconds" },
  { duration: 20000, label: "20 seconds" },
  { duration: 30000, label: "30 seconds" },
  { duration: 60000, label: "1 minute" },
  { duration: 120000, label: "2 minutes" },
  { duration: 300000, label: "5 minutes" },
  { duration: 600000, label: "10 minutes" }

];

const START_DELAY_OPTIONS = [
  { delay: 5000, label: "5 seconds" },
  { delay: 10000, label: "10 seconds" },
  { delay: 15000, label: "15 seconds" },
  { delay: 25000, label: "25 seconds" },
];

function uuidToCustomBase(uuid: string): string {
  // Custom base characters, excluding 'I' and 'O'
  const customBaseChars = "0123456789ABCDEFGHJKLMNPQRSTUVWXYZ";
  const customBase = customBaseChars.length;

  // Remove the dashes from the UUID
  const uuidHex = uuid.replace(/-/g, "");

  // Initialize the result string
  let result = "";

  // Process 4 bytes (8 hex characters) at a time
  for (let i = 0; i < uuidHex.length; i += 8) {
    const fourByteChunk = uuidHex.substring(i, i + 8);

    // Convert the 4-byte chunk to a 32-bit integer
    let intValue = parseInt(fourByteChunk, 16);

    // Initialize the encoded string for this 4-byte chunk
    let encodedChunk = "";

    // Encode the integer into the custom base
    do {
      const remainder = intValue % customBase;
      encodedChunk = customBaseChars[remainder] + encodedChunk;
      intValue = Math.floor(intValue / customBase);
    } while (intValue > 0);
    if (result) {
      result += "-";
    }
    result += encodedChunk;
  }

  return result;
}


const verifyInputs = (state: Recording): [boolean, string] => {
  const missingInfo: string[] = [];

  // Check if name, location, and type are non-empty
  if (!state.name) missingInfo.push("name");
  if (!state.location) missingInfo.push("location");
  if (!state.type) missingInfo.push("type");

  // Check if at least one sensor label is non-empty
  const hasNonEmptyLabel = state.sensors.some(sensor => sensor.label);

  if (!hasNonEmptyLabel) {
    missingInfo.push("at least one sensor label");
  }

  if (missingInfo.length === 0) {
    return [true, "All information is complete"];
  } else {
    return [false, `Missing information for: ${missingInfo.join(', ')}`];
  }
};

interface DeviceGroup {
  group_id: number;
  group_name: string;
  devices: Device[];
}

type DeviceGroups = Record<number, DeviceGroup>;

const Capture = () => {
  const [appState] = useAppState();
  const devices = appState.devices || [];

  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  //console.log(devices)

  const groupedDevices = useMemo(() => {
    // Group devices by group_id
    const groups = devices.reduce((acc: DeviceGroups, device: Device) => {
      if (!acc[device.group_id]) {
        acc[device.group_id] = {
          group_id: device.group_id,
          group_name: device.group_name,
          devices: []
        };
      }
      acc[device.group_id].devices.push(device);
      return acc;
    }, {});

    // Transform the groups object into an array of groups
    return Object.values(groups); // Object.values directly gives us the array of device arrays
  }, [devices]); // Dependency array

  console.log(groupedDevices);

  const [state, setState] = useState<Recording>({
    name: "",
    location: "",
    type: "",
    recording: false,
    recordingId: uuidToCustomBase(uuidv4()), // Initialize with a UUID v4
    duration: 60000, // Default value is 60 seconds
    errorMessage: "",
    periodUs: 1000,
    sensors: [
      { id: "right_wrist", label: "", x: 0, y: 0 },
      { id: "left_wrist", label: "", x: 0, y: 0 },
      { id: "right_foot", label: "", x: 0, y: 0 },
      { id: "left_foot", label: "", x: 0, y: 0 },
      { id: "waist", label: "", x: 0, y: 0 },
      { id: "forehead", label: "", x: 0, y: 0 },
      { id: "left_knee", label: "", x: 0, y: 0 },
      { id: "right_knee", label: "", x: 0, y: 0 },
    ],
  });
  const [startDelay, setStartDelay] = useState<number>(5000);
  const [countdown, setCountdown] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const isCountingDown = useRef<boolean>();


  const initializeNewRecording = () => {
    // Get a new recordingId using uuidToCustomBase function
    const newRecordingId = uuidToCustomBase(uuidv4());

    // Use the setState function to update the state with the new recordingId
    // and clear other fields, preserving the sensor map.
    setState(prevState => ({
      ...prevState,
      recording: false,
      recordingId: newRecordingId,
      errorMessage: ""
    }));
  };


  const startActualRecording = async () => {
    const { name, location, type, recording, recordingId, duration, sensors } =
      state;
    if (!recording) {
      try {
        const devices = sensors
          .map((sensor) => sensor.label)
          .filter((label) => label !== "")
          .join(",");
        const metadata = Object.fromEntries(
          sensors
            .map((sensor) => [sensor.id, sensor.label])
            .filter(([_, label]) => label !== "")
        );

        const params = {
          id: recordingId,
          name,
          location,
          type,
          devices,
          durationMs: duration,
          periodUs: state.periodUs,
          metadata: JSON.stringify(metadata),
        };

        console.log("Performing request using params:", params);

        const endpoint = getEndpoint("http");
        const response = await axios.get(`${endpoint}/startCapture`, {
          params,
        });

        console.log("Recording started:", response.data);
        setState({ ...state, recording: true, errorMessage: "" });
      } catch (error) {
        const errorMessage = `Error starting recording: ${(error as AxiosError)?.response?.data}`;

        setState({ ...state, errorMessage });
      }
    } else {
      setState({ ...state, errorMessage: "Recording already started" });
    }
  };

  const handleStartRecording = () => {
    if (isCountingDown.current) {
      // Prevent initiating a new countdown if one is already in progress.
      return;
    }

    const [result, errorMessage] = verifyInputs(state);
    if (!result) {
      setState((state) => ({
        ...state,
        errorMessage
      }))
      return;
    }

    setShowModal(true);
    setCountdown(startDelay / 1000);
    isCountingDown.current = true;

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          setShowModal(false);
          if (isCountingDown.current) {
            startActualRecording();
          }
          isCountingDown.current = false;
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };



  const setLabel = (sensorId: string, label: string) => {
    const updatedSensors = state.sensors.map((sensor) => {
      if (sensor.id === sensorId) {
        return { ...sensor, label };
      }
      return sensor;
    });
    setState({ ...state, sensors: updatedSensors });
  };

  const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const groupId = parseInt(event.target.value);
    setSelectedGroupId(groupId);

    const selectedGroup = groupedDevices.find(group => group.group_id === groupId);
    if (selectedGroup) {
      const sensorMapping: { [key: string]: string } = {
        lf: "left_foot",
        rf: "right_foot",
        lh: "left_wrist",
        rh: "right_wrist"
      };

      const updatedSensors = state.sensors.map(sensor => {
        const device = selectedGroup.devices.find(device => {
          // Assuming the device's position is stored in its name or another property

          const sensorIdFromDevice = sensorMapping[device.position];
          return sensorIdFromDevice === sensor.id;
        });

        return device ? { ...sensor, label: device.device_name } : sensor;
      });

      setState({ ...state, sensors: updatedSensors });
    }
  };



  const inputClassName = state.recording ? "capture-input-style readonly-input" : "capture-input-style";

  return (
    <div className="capture-header">
      {showModal && (
        <div className="capture-modal">
          <div className="capture-modal-content">
            <div className="capture-modal-title">Recording starting in</div>
            <div className="capture-modal-countdown">{countdown} {countdown === 1 ? 'sec' : 'secs'}</div>
            <button className='app-button' onClick={() => {
              setShowModal(false);
              isCountingDown.current = false;
              setCountdown(0);
            }}>Cancel</button>
            <button className='app-button' onClick={() => {
              setShowModal(false);
              isCountingDown.current = false;
              setCountdown(0);
              startActualRecording();
            }}>Skip</button>
          </div>
        </div>
      )}
      {state.errorMessage && (
        <div className="error-message">{state.errorMessage}</div>
      )}
      <div className="container">
        <div className="capture-input-container">
          <h2>Recording Information</h2>

          <label className="capture-input-label">ID: </label>
          <input
            type="text"
            className="capture-input-style readonly-input"
            value={state.recordingId}
            readOnly // Marking it as readOnly
          />

          <label className="capture-input-label">Device Group: </label>
          <select
            className="capture-input-style"
            value={selectedGroupId?.toString() || ''}
            onChange={handleGroupChange}
          >
            <option value="">Select a group</option>
            {groupedDevices.map((group) => (
              <option key={group.group_id} value={group.group_id}>
                {group.group_name}
              </option>
            ))}
          </select>


          <label className="capture-input-label">Name: </label>
          <input
            type="text"
            className={inputClassName}
            value={state.name}
            onChange={(e: { target: { value: any; }; }) => setState({ ...state, name: e.target.value })}
          />

          <label className="capture-input-label">Location: </label>
          <input
            type="text"
            className={inputClassName}
            value={state.location}
            onChange={(e) => setState({ ...state, location: e.target.value })}
          />

          <label className="capture-input-label">Type: </label>
          <input
            type="text"
            className={inputClassName}
            value={state.type}
            onChange={(e) => setState({ ...state, type: e.target.value })}
          />

          <label className="capture-input-label">Recording Duration: </label>
          <select
            className={inputClassName}
            value={state.duration}
            onChange={(e) =>
              setState({ ...state, duration: parseInt(e.target.value) })
            }
          >
            {DURATION_OPTIONS.map((option, index) => (
              <option key={index} value={option.duration}>
                {option.label}
              </option>
            ))}
          </select>

          <label className="capture-input-label">Sampling Frequency: </label>
          <select
            className={inputClassName}
            onChange={(e) => setState({ ...state, periodUs: parseInt(e.target.value) })}
            value={state.periodUs}
          >
            {PERIOD_OPTIONS.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>


          <label className="capture-input-label">Start Delay: </label>
          <select
            className={inputClassName}
            value={startDelay}
            onChange={(e) => setStartDelay(parseInt(e.target.value))}
          >
            {START_DELAY_OPTIONS.map((option, index) => (
              <option key={index} value={option.delay}>
                {option.label}
              </option>
            ))}
          </select>
          <button className={`app-button ${state.recording ? 'disabled' : ''}`} onClick={handleStartRecording} >
            Start Recording
          </button>
          {
            state.recording && (<button
              className="app-button"
              onClick={initializeNewRecording}
            >
              New Recording
            </button>)
          }

        </div>
        {
          state.recording && <div style={{'margin':'20px'}}> 
                    <RecordingInfo id={state.recordingId} queryInterval={1000} /> 
                    </div>
        }
        {
          !state.recording && <div className="capture-man-section">
            <Man sensors={state.sensors} setLabel={setLabel} />
          </div>
        }

      </div>
    </div>
  );
};

export default Capture;
