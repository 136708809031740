import React from 'react';
import { useParams } from 'react-router-dom';
import RecordingInfo from './RecordingInfo';

const RecordingInfoPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    if (id){
        return <RecordingInfo id={id} />;
    }else{
        return <div>Please provide recording Id</div>
    }

}
export default RecordingInfoPage;
