import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");

const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getEndpoint = (protocol: string) => {
  const endpoint = getQueryParam("endpoint") || window.location.host;
  const isSecure = location.protocol.split(":")[0] == "https";
  let protocolToUse = protocol;
  if (isSecure){
    switch(protocol){
        case "http":
            protocolToUse='https';
            break;
        case "ws":
            protocolToUse="wss";
    }
  }
  return `${protocolToUse}://${endpoint}`;
};

const rotateLeft = (n: number, s: number): number =>
  (n << s) | (n >>> (32 - s));

const cvtHex = (val: number): string => {
  let str = "";
  for (let i = 7; i >= 0; i--) {
    str += ((val >>> (i * 4)) & 0xf).toString(16);
  }
  return str;
};

const sha1 = (msg: string): string => {
  const W: number[] = new Array(80);
  let H0 = 0x67452301;
  let H1 = 0xefcdab89;
  let H2 = 0x98badcfe;
  let H3 = 0x10325476;
  let H4 = 0xc3d2e1f0;
  let A, B, C, D, E;
  let temp: number;

  msg = unescape(encodeURIComponent(msg));
  const msgLen = msg.length;

  const wordArray: number[] = [];
  for (let i = 0; i < msgLen - 3; i += 4) {
    const j =
      (msg.charCodeAt(i) << 24) |
      (msg.charCodeAt(i + 1) << 16) |
      (msg.charCodeAt(i + 2) << 8) |
      msg.charCodeAt(i + 3);
    wordArray.push(j);
  }

  switch (msgLen % 4) {
    case 0:
      wordArray.push(0x080000000);
      break;
    case 1:
      wordArray.push((msg.charCodeAt(msgLen - 1) << 24) | 0x0800000);
      break;
    case 2:
      wordArray.push(
        (msg.charCodeAt(msgLen - 2) << 24) |
          (msg.charCodeAt(msgLen - 1) << 16) |
          0x08000
      );
      break;
    case 3:
      wordArray.push(
        (msg.charCodeAt(msgLen - 3) << 24) |
          (msg.charCodeAt(msgLen - 2) << 16) |
          (msg.charCodeAt(msgLen - 1) << 8) |
          0x80
      );
      break;
  }

  while (wordArray.length % 16 !== 14) wordArray.push(0);
  wordArray.push(msgLen >>> 29);
  wordArray.push((msgLen << 3) & 0x0ffffffff);

  for (let blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
    for (let i = 0; i < 16; i++) W[i] = wordArray[blockstart + i];
    for (let i = 16; i <= 79; i++)
      W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);

    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;

    for (let i = 0; i <= 19; i++) {
      temp =
        (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
        0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (let i = 20; i <= 39; i++) {
      temp =
        (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (let i = 40; i <= 59; i++) {
      temp =
        (rotateLeft(A, 5) +
          ((B & C) | (B & D) | (C & D)) +
          E +
          W[i] +
          0x8f1bbcdc) &
        0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    for (let i = 60; i <= 79; i++) {
      temp =
        (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotateLeft(B, 30);
      B = A;
      A = temp;
    }

    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }

  return cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);
};

/**
 * Hashes a password using bcrypt.
 * @param password - The password to hash.
 * @returns A promise that resolves to the hashed password.
 */
export const hashPassword = async (password: string): Promise<string> => {
  const hash = sha1(password);
  return hash;
};

export const setAuthToken = (auth_token: string) => {
  localStorage.setItem("authToken", auth_token);
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("authToken");
};
