import React from "react";
import QueryParamLink from "./QueryParamLink";
import { useAppState } from './AppState';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from "./util";

type SidebarProps = {
  onLinkClick: () => void;
  visible: boolean;
};

const Sidebar = ({ onLinkClick, visible }: SidebarProps) => {
  const [, setAppState] = useAppState();
  const navigate = useNavigate();
  const handleLogout = () => {
    setAppState((state)=>({
      ...state,
      auth_token:undefined,
    }));
    setAuthToken('');
    navigate('/');
  };

  return (
    <nav className={`App-sidebar ${visible ? "visible" : "hidden"}`}>
      <QueryParamLink className="App-link" to="/" onClick={onLinkClick}>
        <i className="fa fa-home"></i> Home
      </QueryParamLink>
      <QueryParamLink className="App-link" to="/devices" onClick={onLinkClick}>
        <i className="fa fa-desktop"></i> Devices
      </QueryParamLink>
      <QueryParamLink className="App-link" to="/capture" onClick={onLinkClick}>
        <i className="fa fa-camera"></i> Capture
      </QueryParamLink>
      {/* Add the new link for /recordings */}
      <QueryParamLink className="App-link" to="/recordings" onClick={onLinkClick}>
        <i className="fa fa-play-circle"></i> Recordings
      </QueryParamLink>
      {/* Logout link */}
      <QueryParamLink className="App-link" to="/" onClick={handleLogout}>
        <i className="fa fa-sign-out"></i> Logout
      </QueryParamLink>
    </nav>
  );
};

export default Sidebar;
