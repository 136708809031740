
const manPath=`
M3945 9697 c-180 -69 -276 -194 -297 -385 -7 -61 -17 -104 -27 -116 -25 -29 -21 -149 7 -207 12 -25 22 -57 22 -71 0 
-65 80 -253 138 -323 22 -26 24 -35 18 -85 -11 -91 -38 -121 -174 -189 -129 -64 -190 -86 -363 -130 -64 -16 -136 -39 
-160 -52 -69 -35 -159 -133 -205 -224 -50 -98 -64 -152 -74 -275 -4 -52 -21 -162 -38 -245 -18 -82 -39 -186 -48 -230 
-22 -110 -30 -373 -15 -494 l13 -96 -35 -75 c-48 -103 -64 -171 -76 -335 -6 -77 -18 -221 -26 -320 -8 -99 -17 -218 -21 
-265 -3 -47 -15 -114 -26 -150 -32 -103 -47 -195 -54 -335 -7 -137 9 -269 40 -337 8 -18 24 -55 36 -83 31 -73 128 -232 
158 -261 27 -24 47 -29 76 -18 24 9 20 78 -10 164 -26 76 -49 228 -40 265 23 93 34 119 41 100 56 -160 92 -199 132 -139 
35 54 15 331 -38 521 l-20 72 56 143 c31 79 67 167 81 196 13 29 24 56 24 60 0 4 18 52 41 107 66 161 68 166 89 256 29 
125 43 237 36 295 -5 42 -1 62 19 107 14 30 32 92 41 138 8 46 19 100 23 119 l8 35 17 -38 c30 -67 36 -135 36 -379 0 
-171 -4 -257 -14 -301 -33 -144 -41 -219 -42 -432 0 -150 -6 -254 -17 -328 -17 -110 -20 -122 -55 -262 -40 -159 -55 -331 
-49 -570 8 -298 37 -497 102 -704 l26 -81 -10 -368 c-6 -202 -14 -381 -19 -398 -22 -73 -34 -247 -30 -429 4 -163 10 -212 
36 -340 28 -135 116 -487 171 -685 24 -89 26 -138 6 -204 -8 -27 -15 -72 -15 -100 0 -94 -30 -166 -110 -264 -28 -35 -56 
-75 -61 -90 -15 -38 -13 -102 4 -119 20 -20 88 -35 192 -43 6 0 37 1 70 3 36 2 74 -2 94 -10 66 -26 115 -15 172 38 53 49 
73 94 71 159 -1 30 -3 143 -5 250 l-3 195 -33 70 -33 70 12 175 c14 213 23 287 46 410 22 118 26 548 7 860 -9 140 -10 
222 -3 260 8 45 28 193 54 390 2 19 18 78 34 131 16 53 35 127 42 165 6 38 29 159 50 269 21 110 41 232 45 270 4 39 11 
79 15 90 9 23 12 7 30 -130 24 -183 99 -558 135 -675 15 -47 31 -119 36 -160 5 -41 20 -145 32 -230 24 -168 26 -387 6 
-545 -16 -118 -12 -510 6 -630 38 -263 46 -327 54 -477 9 -172 3 -218 -36 -276 -16 -23 -18 -49 -18 -192 0 -91 -4 -199 
-9 -241 -8 -65 -6 -81 11 -120 50 -110 150 -165 231 -126 15 7 50 11 77 10 78 -5 97 -5 145 1 140 17 155 28 148 110 -4 
46 -12 64 -49 111 -103 131 -129 190 -129 297 -1 29 -7 71 -15 93 -20 56 -18 129 5 205 33 107 146 565 177 715 26 132 28 
154 27 380 -1 185 -5 258 -18 320 -13 60 -20 171 -27 443 -10 352 -10 364 10 425 127 392 148 998 46 1337 -7 25 -18 74 -24 
110 -6 36 -18 109 -27 164 -13 77 -15 129 -10 235 7 141 -8 315 -39 464 -21 102 -21 539 0 621 30 113 37 113 57 -3 10 -57 
29 -131 42 -165 18 -46 25 -88 30 -176 3 -76 15 -155 35 -235 27 -114 53 -182 220 -598 l72 -178 -20 -72 c-45 -161 -71 
-437 -47 -505 9 -27 39 -44 61 -36 18 7 47 61 65 122 7 23 16 42 19 42 4 0 14 -28 24 -62 22 -79 12 -197 -27 -308 -32 -92 
-34 -150 -6 -168 22 -14 54 -2 78 30 7 9 18 23 25 30 26 26 93 152 158 295 66 147 61 464 -10 688 -13 40 -25 130 -35 275 
-49 640 -51 656 -113 792 l-36 78 12 83 c15 114 14 314 -5 427 -8 52 -19 124 -25 160 -5 36 -21 115 -35 175 -14 61 -30 157 
-34 215 -9 124 -39 219 -99 318 -83 138 -153 185 -342 232 -155 39 -258 76 -379 138 -113 57 -150 100 -158 182 -4 46 -1 55 
35 108 63 92 123 241 123 309 0 17 4 34 9 38 5 3 17 37 25 75 18 76 11 137 -18 169 -11 13 -16 39 -17 92 -1 169 -107 320 
-274 388 -48 20 -81 25 -170 28 -96 3 -118 0 -170 -20z m321 -52 c158 -60 240 -179 251 -365 5 -77 9 -97 24 -108 27 -18 25 
-85 -5 -169 -13 -37 -29 -95 -35 -128 -10 -53 -71 -201 -89 -215 -31 -23 -52 -74 -52 -126 0 -79 17 -129 59 -170 72 -71 352 
-188 532 -224 137 -27 238 -105 306 -236 53 -102 73 -175 73 -261 0 -39 9 -105 20 -148 30 -116 77 -383 92 -512 12 -109 9 -161 
-19 -373 -4 -27 4 -55 30 -111 19 -41 42 -101 51 -134 8 -34 31 -249 51 -480 27 -326 40 -433 56 -480 44 -131 63 -395 38 -530 
-20 -107 -142 -359 -204 -421 -13 -14 -15 -13 -15 10 0 27 24 109 40 138 6 10 10 35 10 56 0 21 5 63 11 93 10 49 8 64 -15 140 
-14 46 -29 113 -32 147 -7 66 -26 104 -43 87 -5 -5 -21 -64 -34 -130 -14 -66 -30 -133 -37 -150 -11 -28 -12 -29 -22 -11 -26 46 
9 349 58 498 l15 47 -55 141 c-31 77 -60 148 -65 158 -5 9 -16 35 -24 57 -13 35 -46 118 -117 295 -32 80 -70 292 -70 392 0 59 -5 
103 -14 119 -34 66 -76 257 -76 350 0 43 -23 79 -40 63 -17 -17 -79 -161 -95 -224 -21 -77 -21 -532 0 -610 34 -129 48 -270 42 -444 
-3 -94 -2 -193 3 -220 5 -28 19 -105 30 -171 11 -66 27 -145 35 -175 92 -329 67 -970 -50 -1295 -19 -52 -22 -157 -12 -490 5 -174 
15 -323 29 -420 29 -211 22 -504 -15 -685 -29 -135 -132 -551 -172 -689 -29 -97 -32 -163 -10 -214 8 -20 15 -61 15 -92 0 -71 27 -171 
57 -212 13 -17 23 -35 23 -38 0 -3 16 -23 35 -43 40 -43 78 -114 73 -139 -4 -19 -43 -34 -108 -39 -48 -5 -63 -5 -151 -3 -31 1 
-65 -4 -77 -10 -42 -22 -89 -13 -132 25 -58 52 -67 91 -51 220 9 68 11 144 6 213 -7 105 -6 110 21 167 23 48 29 76 32 150 4 99 
-20 381 -43 509 -29 157 -40 417 -25 591 21 256 24 537 7 630 -9 43 -21 133 -28 199 -7 76 -26 168 -49 251 -21 71 -43 164 -49 
205 -7 41 -19 103 -26 139 -45 210 -78 434 -96 635 -6 72 -16 135 -21 142 -16 19 -38 3 -38 -28 0 -52 -40 -391 -61 -509 -46 -272 
-96 -505 -125 -595 -13 -38 -31 -131 -39 -205 -9 -74 -23 -175 -31 -225 -18 -106 -19 -389 -2 -504 20 -131 13 -514 -12 -676 -37 
-240 -42 -295 -43 -475 -2 -173 -2 -176 26 -234 28 -57 29 -61 22 -173 -5 -75 -3 -145 5 -206 17 -125 9 -161 -46 -214 -35 -34 -52
 -43 -80 -43 -19 0 -43 4 -53 9 -15 8 -51 11 -161 11 -88 0 -110 3 -115 11 -4 5 -17 9 -31 9 -14 0 -27 7 -30 15 -6 14 13 86 25 
 95 19 15 103 126 115 153 26 57 46 140 46 191 0 28 7 73 15 100 17 56 19 114 6 149 -10 25 -98 360 -131 497 -50 206 -60 253
 -75 354 -20 140 -21 405 0 551 8 61 20 232 25 380 5 149 12 302 15 340 7 85 -5 171 -38 285 -72 241 -105 568 -88 843 12 173 
 21 242 56 392 13 55 31 143 40 195 8 52 20 119 25 147 5 29 7 119 3 200 -6 165 7 325 38 445 16 64 19 111 19 295 -1 281 -8
 339 -62 460 -39 86 -60 115 -73 101 -3 -3 -12 -54 -20 -114 -17 -134 -40 -235 -66 -291 -14 -31 -19 -64 -19 -126 0 -91 -18 
 -211 -49 -322 -17 -63 -114 -319 -154 -405 -8 -19 -41 -100 -72 -179 l-56 -145 20 -60 c23 -69 47 -211 57 -338 7 -87 -1 -168 
 -15 -152 -13 15 -40 113 -57 205 -14 74 -20 89 -35 89 -20 0 -21 -4 -39 -113 -6 -37 -19 -95 -30 -130 -18 -55 -18 -72 -9 -152
 6 -49 22 -121 35 -159 27 -79 33 -140 11 -116 -33 36 -122 190 -158 274 -51 117 -63 197 -56 371 5 139 21 238 52 325 15 41 
 29 183 65 650 19 251 31 312 88 433 l36 78 -13 72 c-20 118 -25 239 -12 343 17 139 47 310 78 439 14 64 26 146 27 195 3 114
 34 209 102 317 36 57 111 133 131 133 7 0 13 4 13 9 0 11 61 32 195 65 136 34 192 54 318 116 172 82 216 135 217 257 0 56
 -4 69 -34 110 -55 78 -93 163 -114 258 -11 50 -27 108 -36 130 -23 60 -20 165 5 150 12 -8 18 26 21 120 3 68 8 91 37 150 
 41 84 98 144 171 181 77 38 94 42 202 43 81 1 109 -3 164 -24z
`;

export default manPath;