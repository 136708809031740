import { Link, useLocation } from 'react-router-dom';

type QueryParamLinkProps = {
  to: string;
  [key: string]: unknown;
};

const QueryParamLink = ({ to, ...rest }: QueryParamLinkProps) => {
  const location = useLocation();
  const queryParams = location.search;
  
  return <Link to={`${to}${queryParams}`} {...rest} />;
};

export default QueryParamLink;

