import React, { useState, useContext } from 'react';
import { useAppState } from './AppState';
import { hashPassword, getEndpoint, setAuthToken } from './util';
import axios from 'axios';



const LoginComponent: React.FC = () => {
    const [state, setAppState] = useAppState();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const onLogin = async (username: string, password: string): Promise<boolean> => {
        const hash = await hashPassword(password);
        const endpoint = getEndpoint("http");
        const params = {
            login: username,
            password_hash: hash
        };
        try {
            const response = await axios.get(`${endpoint}/login`, {
                params,
            });
            if (response.status !== 200) {
                return false;
            }
            const auth_token = response.data.token;
            const { id, login, email, privileges } = response.data;
            setAuthToken(auth_token);
            setAppState(prevState => ({
                ...prevState, auth_token,
                user: {
                    id,
                    login,
                    email,
                    privileges
                }
            }));
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isValid = await onLogin(username, password);
        if (!isValid) {
            setError('Invalid password or username');
        } else {
            setError(null);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSubmit(e as any);
        }
    };

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <div className="login-input-group">
                    <label className="capture-input-label" htmlFor="login">Login</label>
                    <input
                        type="text"
                        id="login"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        className="capture-input-style"
                        autoFocus // Added autoFocus here
                    />
                </div>
                <div className="login-input-group">
                    <label className="capture-input-label" htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} // Added onKeyDown here
                        className="capture-input-style"
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                <button className="app-button" onClick={handleSubmit}>Login</button>
            </div>
        </div>
    );
};

export default LoginComponent;
