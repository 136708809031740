import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppState } from "./AppState";
import { getEndpoint } from './util';
import QueryParamLink from './QueryParamLink'; // Update the path accordingly


type RecordingRow = {
    id: string;
    name: string;
    location: string;
    type: string;
    duration_ms: number;
    period_us: number;
    devices: string;
    recording_timestamp: string;
    metadata: string;
    user_id: number;
}

type Recordings = RecordingRow[];

function ListRecordings() {
    const [appState] = useAppState();
    const [recordings, setRecordings] = useState<Recordings>([]);
    const [skip, setSkip] = useState<number>(0);
    const [searchFields, setSearchFields] = useState({
        name: "",
        location: "",
        type: "",
        start_date: "",
        end_date: ""
    });

    useEffect(() => {
        fetchRecordings();
    }, [skip]);

    const fetchRecordings = async () => {
        try {
            const endpoint = getEndpoint('http');
            const url = `${endpoint}/listRecordings`;
            const response = await axios.get(url, {
                params: {
                    skip,
                    limit: 20,
                    ...searchFields
                }
            });
            setRecordings(response.data);
        } catch (error) {
            console.error("Error fetching recordings:", error);
        }
    };

    return (
        <div className="recordings-container">
            
            <div className="recordings-search-bar">
                {Object.entries(searchFields).map(([key, value]) => (
                    <label key={key} className="recordings-search-label">
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                        <input
                            type={key.includes("date") ? "date" : "text"}
                            value={value as string}
                            onChange={e => setSearchFields(prev => ({ ...prev, [key]: e.target.value }))}
                        />
                    </label>
                ))}
                <button className="app-button" onClick={fetchRecordings}>Search</button>
            </div>

            <div className="recordings-table-container">
                <div className="recordings-row recordings-header-row">
                    <div className="recordings-cell">ID</div>
                    <div className="recordings-cell">Name</div>
                    <div className="recordings-cell">Location</div>
                    <div className="recordings-cell">Type</div>
                    <div className="recordings-cell">Timestamp</div>
                </div>

                {recordings.map(rec => (
                    <QueryParamLink to={`/recordingInfo/${rec.id}`} key={rec.id}>
                        <div className="recordings-row">
                            <div className="recordings-cell">{rec.id}</div>
                            <div className="recordings-cell">{rec.name}</div>
                            <div className="recordings-cell">{rec.location}</div>
                            <div className="recordings-cell">{rec.type}</div>
                            <div className="recordings-cell">{rec.recording_timestamp}</div>
                        </div>
                    </QueryParamLink>
                ))}
            </div>

            <div className="recordings-pagination-controls">
                <button onClick={() => setSkip(0)}>&laquo;</button>
                <button onClick={() => setSkip(prev => (prev <= 0 ? 0 : prev - 10))}>&lt;</button>
                <span>Page {Math.ceil(skip/10 + 1)}</span>
                <button onClick={() => setSkip(prev => prev + 10)}>&gt;</button>
                
            </div>
        </div>
    );
                }

export default ListRecordings;
