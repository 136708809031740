import React, { createContext, useContext, useState } from 'react';
import {AppState} from './types';

const AppStateContext = createContext<[AppState, React.Dispatch<React.SetStateAction<AppState>>] | undefined>(undefined);


const AppStateProvider = ({ children }:{children:React.ReactNode}) => {
    const appStateTuple = useState<AppState>(() => ({
        auth_token: localStorage.getItem('authToken') ?? ''
     }));
    return (
        <AppStateContext.Provider value={appStateTuple}>
            {children}
        </AppStateContext.Provider>
    );
};


const useAppState = () => {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error("useAppState must be used within AppStateProvider");
    }
    return context;
};

export { AppStateProvider, useAppState };
