import React, { useState, useEffect, useRef } from "react";
import { getEndpoint } from "./util";
import ace from "ace-builds/src-noconflict/ace";
import AceEditor from "react-ace";
import { v4 as uuidv4 } from 'uuid';

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

type DeviceProps = {
  device: {
    device_name: string;
    last_checked: string;
  };
  onClose: () => void;
};

interface WebSocketWithSessionId extends WebSocket {
  sessionId: string;
}



export const DeviceDialog = ({ device, onClose }: DeviceProps) => {

  const endpoint = getEndpoint("ws");

  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState("{}");
  const [errorMessage, setErrorMessage] = useState("");
  const [cmd, setCmd] = useState("");
  const [log, setLog] = useState<Array<string>>([]);

  const handleClearLog = () => {
    setLog([]);
  };

  const logEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    logEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [log]);

  ace.config.set("basePath", "ace-builds/src-noconflict");

  const [client,setClient] = useState<WebSocketWithSessionId | null>(null);

  useEffect(() => {
    let isOpen = false;
    const ws = (new WebSocket(endpoint) as WebSocketWithSessionId);
    const sessionId = uuidv4();
    ws.sessionId = sessionId;
    ws.onopen = () => {
      isOpen=true;
      console.log("WebSocket Client Connected");
      const subscribeMessage = {
        src: sessionId,
        type: "subscribe",
        dst: "",
        payload: { dst: sessionId },
      };
      ws.send(JSON.stringify(subscribeMessage) + "\n");
    };
    ws.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      const data = dataFromServer?.payload?.data ?? "";
      setLog((log) => [...log, data]);
    };
    setClient(ws);
    return () => {
      if (isOpen){
        ws.close();
      }
    };    
  }, [endpoint]);

  const sendCommand = async () => {
    if (!client){
      return;
    }
    setErrorMessage(""); // Reset error message
    setIsLoading(true);
    try {
      const commandData = {
        src: client.sessionId,
        type: "cmd",
        dst: device.device_name,
        payload: {
          cmd,
          ...JSON.parse(payload),
        },
      };

      client.send(JSON.stringify(commandData) + "\n");
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending command:", error);
      setErrorMessage("Error sending command. Please try again later.");
      setIsLoading(false);
    }
  };

  return (
    <div className="ts-modal-background">
      <div className="ts-modal">
        <h2>{device.device_name}</h2>
        <p>Last checked: {new Date(device.last_checked).toLocaleString()}</p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="ts-section-title">Cmd:</span>
          <input
            type="text"
            value={cmd}
            onChange={(e) => setCmd(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") sendCommand();
            }}
            style={{ marginRight: "10px", flex: 1 }}
            disabled={isLoading}
          />
          <button
            className="ts-button"
            onClick={sendCommand}
            disabled={isLoading}
          >
            {isLoading && <div className="spinner" />}
            Send Command
          </button>
        </div>

        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span className="ts-section-title">Payload:</span>
          <AceEditor
            mode="json"
            theme="github"
            onChange={setPayload}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            value={payload}
            setOptions={{ useWorker: false }}
            className = "ts-payload-editor"
            style={{ height: "50px" }}
            readOnly={isLoading}
          />
        </div>
        <button onClick={handleClearLog}>Clear Log</button>
        <div className="ts-log-viewer" style={{ fontFamily: "monospace" }}>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
              textAlign: "left",
            }}
          >
            {log.map((msg, i) => (
              <li
                key={i}
                style={{
                  padding: "5px",
                  backgroundColor: i % 2 === 0 ? "#f6f6f6" : "white",
                  overflowWrap: "break-word",
                }}
              >
                {msg}
              </li>
            ))}
          </ul>
          <div ref={logEndRef} />
        </div>

        <p style={{ color: "red" }}>{errorMessage}</p>

        <button className="app-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
