import { useState } from "react";
import { DeviceDialog } from "./DeviceDialog";
import { getEndpoint } from "./util";
import { useAppState } from './AppState';
import {Device} from './types';


const Devices = () => {
  const endpoint = getEndpoint("http");
  const [appState] = useAppState();
  const [activeDevice, setActiveDevice] = useState<Device | null>(null);

  const handleDeviceClick = (device: Device) => {
    setActiveDevice(device);
  };

  const closeDialog = () => {
    setActiveDevice(null);
  };

  const timeDifferenceInHours = (dateString: string) => {
    const lastChecked = new Date(dateString);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - lastChecked.getTime();
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
    return timeDifferenceInHours;
  };

  const devices = appState.devices || [];

  return (
    <div className="ts-container">
      <h1>Devices</h1>

      <div className="ts-container">
        {devices.map((device) => {
          const timeDifference = timeDifferenceInHours(device.last_checked);
          const deviceStyle = {
            ...{ color: timeDifference > 1 ? "gray" : "black" },
            ...{
              backgroundColor: timeDifference > 1 ? "#eaeaea" : "transparent",
            },
          };

          return (
            <div
              key={device.device_id}
              className="ts-device"
              style={deviceStyle}
              onClick={() => handleDeviceClick(device)}
              onMouseEnter={(e) => ((e.target as HTMLElement).style.backgroundColor = "#f0f0f0")}
              onMouseLeave={(e) =>
                ((e.target as HTMLElement).style.backgroundColor =
                  timeDifference > 1 ? "#eaeaea" : "transparent")
              }
            >
              {device.device_name} - {device.group_id}
            </div>
          );
        })}

        {activeDevice && (
          <DeviceDialog device={activeDevice} onClose={closeDialog} />
        )}
      </div>
    </div>
  );
};

export default Devices;
