import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { getEndpoint } from './util';

type MetadataType = {
    [key: string]: string;
};

type RecordingInfoType = {
    id: string;
    name: string;
    location: string;
    type: string;
    duration_ms: number;
    period_us: number;
    devices: string;
    recording_timestamp: string;
    metadata: MetadataType;
    user_id: number;
    files: {
        name: string;
        size: string;
    }[];
}

type Props = {
    id: string;
    queryInterval?: number; // in milliseconds
}

const RecordingInfo: React.FC<Props> = ({ id, queryInterval }) => {
    const [recordingInfo, setRecordingInfo] = useState<RecordingInfoType | null>(null);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [statusColor, setStatusColor] = useState<string>("red");
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [filesLeft, setFilesLeft] = useState(0);

    const fileInputRef = useRef<HTMLInputElement>(null); // Step 1: Create a ref

    const fetchRecordingInfo = useCallback(async () => {
        if (!id) return;
        try {
            const endpoint = getEndpoint('http');
            const url = `${endpoint}/getRecordingInfo?id=${id}`;
            const response = await axios.get(url);
            const data: RecordingInfoType = {
                ...response.data,
                metadata: JSON.parse(response.data.metadata || "{}"), // Ensure metadata is always an object
            };
            setRecordingInfo(data);
            checkRecordingStatus(data);
        } catch (error) {
            console.error("Error fetching recording info:", error);
        }
    }, [id]);

    useEffect(() => {
        fetchRecordingInfo();

        if (queryInterval) {
            const interval = setInterval(fetchRecordingInfo, queryInterval);
            return () => clearInterval(interval);
        }
    }, [fetchRecordingInfo, queryInterval]);

    const checkRecordingStatus = useCallback((recording: RecordingInfoType) => {
        const missingDevices: string[] = [];

        for (const [position, deviceId] of Object.entries(recording.metadata)) {
            const dataFile = `${deviceId}.data`;
            const jsonFile = `${deviceId}.json`;

            const dataFileInfo = recording.files.find(file => file.name.split('/').pop() === dataFile);
            const jsonFileInfo = recording.files.find(file => file.name.split('/').pop() === jsonFile);

            if (!dataFileInfo || !jsonFileInfo || dataFileInfo.size === "0" || jsonFileInfo.size === "0") {
                missingDevices.push(deviceId);
            }
        }

        if (missingDevices.length > 0) {
            setStatusMessage(`Recording not ready. We're missing info from devices [${missingDevices.join(",")}]`);
            setStatusColor("red");
        } else {
            setStatusMessage(`Recording is OK. It contains info from devices [${Object.values(recording.metadata).join(",")}]`);
            setStatusColor("green");
        }
    }, []);

    const handleDownload = async () => {
        if (!recordingInfo) {
            return;
        }
        try {
            const endpoint = getEndpoint('http');
            const url = `${endpoint}/getRecordingZip?recording_id=${id}`;
            const response = await axios.get(url, { responseType: 'blob' }); // Set response type to blob
            const urlObject = window.URL.createObjectURL(new Blob([response.data]));

            const downloadFileName = `${recordingInfo.name}.zip`;

            const link = document.createElement('a');
            link.href = urlObject;
            link.setAttribute('download', downloadFileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading the zip:", error);
        }
    }

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!id) {
            return;
        }
        const files = event.target.files;
        if (!files) return;

        setUploading(true);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            setFilesLeft(files.length - i);

            const validFileTypes = ['.apd', '.xml', '.csv'];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !validFileTypes.includes(`.${fileExtension}`)) {
                alert("Invalid file type. Please upload .apd, .xml, or .csv files only.");
                continue;
            }

            const endpoint = getEndpoint('http');
            const fileName = `recordings/${id}/${file.name}`;
            const url = `${endpoint}/uploadFile?filename=${encodeURIComponent(fileName)}`;

            try {
                await axios({
                    method: 'post',
                    url: url,
                    data: file,
                    onUploadProgress: progressEvent => {
                        setUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)));
                    },
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                });
            } catch (error) {
                console.error("Error uploading the file:", error);
                alert("An error occurred while uploading the file. Please try again.");
            }
        }

        setUploading(false);
        setUploadProgress(0);
        setFilesLeft(0);
        fetchRecordingInfo();
    };

    return (
        <div className="container">
            {recordingInfo ? (
                <div className="details-container">
                    <div style={{ color: statusColor, fontWeight: 'bold', marginBottom: '10px' }}>
                        {statusMessage}
                    </div>
                    <div className="basic-details">
                        <div><strong>ID:</strong> {recordingInfo.id}</div>
                        <div><strong>Name:</strong> {recordingInfo.name}</div>
                        <div><strong>Location:</strong> {recordingInfo.location}</div>
                        <div><strong>Type:</strong> {recordingInfo.type}</div>
                        <div><strong>Duration (ms):</strong> {recordingInfo.duration_ms}</div>
                        <div><strong>Period (us):</strong> {recordingInfo.period_us}</div>
                        <div><strong>Devices:</strong> {recordingInfo.devices}</div>
                        <div><strong>Timestamp:</strong> {recordingInfo.recording_timestamp}</div>
                        <div><strong>User ID:</strong> {recordingInfo.user_id}</div>
                    </div>

                    <div className="recordings-table-container">
                        <div className="recordings-row recordings-header-row">
                            <div className="recordings-cell">Position</div>
                            <div className="recordings-cell">Device</div>
                        </div>
                        {Object.entries(recordingInfo.metadata).map(([key, value]) => (
                            <div key={key} className="recordings-row">
                                <div className="recordings-cell">{key}</div>
                                <div className="recordings-cell">{value}</div>
                            </div>
                        ))}
                    </div>

                    <h3>Files</h3>
                    <div className="recordings-table-container">
                        <div className="recordings-row recordings-header-row">
                            <div className="recordings-cell">Name</div>
                            <div className="recordings-cell">Size (bytes)</div>
                        </div>
                        {recordingInfo.files.map(file => (
                            <div key={file.name} className="recordings-row">
                                <div className="recordings-cell">{file.name.split('/').pop()}</div>
                                <div className="recordings-cell">{file.size}</div>
                            </div>
                        ))}
                    </div>
                    {uploading && (
                        <div>
                            <div>Uploading: {uploadProgress}%</div>
                            <div>Files left: {filesLeft}</div>
                            <progress value={uploadProgress} max={100}></progress>
                        </div>
                    )}
                    <div className="button-container" style={{ display: 'flex', gap: '10px' }}>
                        <div className="tooltip">
                            <button className="app-button" onClick={() => fileInputRef.current?.click()}>
                                Upload Files
                            </button>
                            <span className="tooltip-text">Upload files associated to this capture, such as APD files or XML files</span>
                        </div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept=".apd,.xml,.csv"
                            onChange={handleFileUpload}
                            multiple
                            style={{ display: 'none' }}
                        />
                        <div className="tooltip">
                            <button className="app-button" onClick={handleDownload}>
                                Download ZIP
                            </button>
                            <span className="tooltip-text">Download all files associated to this capture as a ZIP file</span>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading recording info...</p>
            )}
        </div>
    );
}

export default RecordingInfo;

